var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "card-border-blue-light",
                  attrs: { "no-body": "" },
                },
                [
                  _vm.User
                    ? _c(
                        "div",
                        { staticClass: "px-0", class: _vm.fillClassContainer },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "container-header-card-collapsible cursor-pointer w-100",
                              on: { click: _vm.handleClickHeader },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("div", { staticClass: "label w-90" }, [
                                  _c("h4", [
                                    _vm._v(_vm._s(_vm.FormMSG(1, "Profile"))),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "icon w-10" },
                                  [
                                    !_vm.configAccordion.isVisible
                                      ? _c(_vm.getIconLucide("ChevronDown"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 22 },
                                        })
                                      : _vm._e(),
                                    _vm.configAccordion.isVisible
                                      ? _c(_vm.getIconLucide("ChevronUp"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 22 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              class: _vm.$screen.width <= 576 ? "mt-3" : "",
                              attrs: { visible: _vm.configAccordion.isVisible },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-card-collapsible" },
                                [
                                  _c("profile", {
                                    attrs: {
                                      selectedUserData: _vm.selectedUserData,
                                      data: _vm.User,
                                      disabled: true,
                                      handleAccessLevel: (
                                        item,
                                        value,
                                        data,
                                        screen
                                      ) =>
                                        this.$props.handleAccessLevel(
                                          item,
                                          value,
                                          data,
                                          screen
                                        ),
                                    },
                                    on: {
                                      change: _vm.handleUserEntryLevelChange,
                                    },
                                  }),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center pt-4",
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "4",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "w-100",
                                              attrs: {
                                                block: "",
                                                variant: "secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateUser()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      50,
                                                      "Save user"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isDev
                                    ? _c(
                                        "template",
                                        { slot: "footer" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.becomeUser(
                                                    _vm.userId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Impersonate")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          this.isForProject()
            ? _c(
                "b-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _vm._l(_vm.contracts, function (item) {
                    return _c("contract", {
                      key: item.id,
                      attrs: {
                        disableDelete: _vm.contracts.length === 1,
                        contractInput: item,
                        accordion: _vm.accordion,
                        "hide-send-package-btn": true,
                        "fill-class-container": "container-layout",
                        "user-id": _vm.userId,
                        "show-pay-code": _vm.showPayCode,
                      },
                      on: {
                        isLoading: function ($event) {
                          _vm.isLoading = $event
                        },
                        successModal: function ($event) {
                          _vm.successModal = $event
                        },
                        erreur: function ($event) {
                          _vm.erreur = $event
                        },
                        "contract:show-body": _vm.handleContractShowBody,
                      },
                    })
                  }),
                  _c(
                    "b-row",
                    {
                      staticClass: "d-flex justify-content-center",
                      class: `${_vm.displayAddBtn ? "pb-2" : ""}`,
                    },
                    [
                      _c(
                        "b-col",
                        {
                          style: `display: ${
                            _vm.displayAddBtn ? "block" : "none"
                          } !important;`,
                          attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              ref: "new-contract",
                              attrs: { size: "lg", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addContract(_vm.userId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(this.FormMSG(51, "New contract")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              staticClass: "modal-success",
              attrs: {
                "header-class": "header-class-modal-doc-package",
                title: _vm.FormMSG(60, "Success!"),
                "ok-variant": "success",
                "ok-only": "",
              },
              on: {
                ok: function ($event) {
                  _vm.successModal = false
                },
              },
              model: {
                value: _vm.successModal,
                callback: function ($$v) {
                  _vm.successModal = $$v
                },
                expression: "successModal",
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(this.FormMSG(61, "Your information has been saved.")) +
                  "\n\t\t"
              ),
            ]
          ),
          _c("loading", {
            attrs: { active: _vm.isLoading, "is-full-page": true },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }